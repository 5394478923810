@import '../../assets/scss/now-ui-dashboard/variables';

.wizard-navigation {
  display: none;
}

.wizard-container {
  [data-background-color="blue"] {
    background-color: $info-color;
  }

  .card-wizard .card-footer .btn {
    background-color: $primary-color;

    &:hover {
      background-color: $primary-states-color;
    }
  }
}