.modal.right .modal-dialog {
	position: fixed;
	margin: auto;
	width: 100%;
	height: 100%;
	padding: 2rem 0;
	-webkit-transform: translate3d(0%, 0, 0);
	-ms-transform: translate3d(0%, 0, 0);
	-o-transform: translate3d(0%, 0, 0);
	transform: translate3d(0%, 0, 0);
}

.modal.right .modal-content {
	height: 100%;
	overflow-y: auto;
}

.modal.right.fade .modal-dialog {
	right: 0;
	-webkit-transition: opacity 0.3s linear, right 0.3s ease-out;
	-moz-transition: opacity 0.3s linear, right 0.3s ease-out;
	-o-transition: opacity 0.3s linear, right 0.3s ease-out;
	transition: opacity 0.3s linear, right 0.3s ease-out;
}

.modal.right.fade.in .modal-dialog {
	right: 0;
}

i.fas.fa-angle-double-right.mr-2 {
	position: fixed;
	display: inline-block;
	border-radius: 10em;
	box-shadow: 0px 0px 2px white;
	padding: 0.5em 0.6em;
	left: -1em;
	top: 2.2em;
	background: white;
	overflow: visible;
	z-index: 1;
}

i.fas.fa-angle-double-right.mr-2::before {
	overflow: visible;
	z-index: 1;
}

#horizontal-rule {
	width: 40%;
	height: 1px;
	opacity: 0.23;
	border: solid 1px #979797;
	display: inline-block;
}

#casino-details-modal {
	display: block;
}